:root {
  --primary: #000;
  --background: #fafafa;
  --secondary-background: #f2f2f2;
  --gray: #e1e1e1;
  --gray-hover: #171717;
  --primary-hover: #555;
  --link-gray: #666;
  --link-gray-hover: #171717;
  --gray-border: #e6e6e6;
  --white: #fcfcfc;
  --navbar-height: 64px;
}

body {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.menu__wrapper {
  z-index: 2;
  background: var(--background);
  opacity: .8;
  height: var(--navbar-height);
  background-color: #ebeee8;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  width: 100vw;
  padding: 0 1rem;
  display: flex;
  position: fixed;
  box-shadow: 0 1px #0000001a;
}

.menu__bar {
  align-items: center;
  display: flex;
}

.logo {
  cursor: pointer;
  align-items: center;
  display: none;
}

.logo svg {
  fill: var(--primary);
}

.navigation {
  background: var(--background);
  background-color: #0000;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-left: 1rem;
  list-style-type: none;
  display: flex;
}

.navigation > li {
  cursor: pointer;
  align-items: center;
  min-height: 2.25rem;
  display: flex;
  position: relative;
}

.navigation > li > a {
  color: var(--link-gray);
  font-size: 14px;
  text-decoration: none;
}

.navigation > li > a:hover {
  color: var(--link-gray-hover);
}

.navigation > li > button {
  color: var(--link-gray);
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
  background: none;
  border: none;
  border-radius: 10rem;
  flex-wrap: nowrap;
  align-items: center;
  gap: .25rem;
  min-height: 2.25rem;
  margin-right: -.25rem;
  padding: .25rem .6125rem;
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: flex;
  position: relative;
}

.navigation > li > button > svg {
  fill: var(--link-gray);
  margin-top: 2px;
  transition: all .2s ease-in-out;
}

.navigation > li:hover > button svg {
  fill: var(--link-gray-hover);
  rotate: 180deg;
}

.navigation > li:hover > button {
  color: var(--link-gray-hover);
  background: var(--gray);
}

.navigation > li:hover .dropdown, .navigation > li:focus-within .dropdown {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform-origin: top;
    transform: scale(.99)translateY(-.7em);
  }

  to {
    opacity: 1;
    transform: scale(1)translateY(0);
  }
}

.dropdown__wrapper {
  padding-top: 3.75rem;
  position: absolute;
  top: 0;
  left: 0;
}

.dropdown {
  background: var(--white);
  border: 1px solid var(--gray-border);
  z-index: 2;
  border-radius: .5rem;
  width: 100%;
  max-width: 36rem;
  padding: .5rem;
  animation: .2s ease-in-out fadeIn;
  display: none;
  box-shadow: 0 50px 100px -20px #0000001f, 0 30px 60px -30px #00000026;
}

.list-items-with-description {
  grid-template-columns: 1fr 1fr;
  width: 100%;
  list-style-type: none;
  display: grid;
}

.list-items-with-description li {
  border-radius: .375rem;
  gap: 1rem;
  width: 100%;
  padding: .5rem;
  transition: all .2s ease-in-out;
  display: flex;
}

.list-items-with-description li:hover {
  background: var(--secondary-background);
}

.list-items-with-description li svg {
  stroke: var(--link-gray-hover);
  stroke-width: 1px;
  width: 1.5rem;
  height: 1.5rem;
}

.item-title h3 {
  color: var(--link-gray-hover);
  font-size: .875rem;
  font-weight: 500;
}

.item-title p {
  white-space: nowrap;
  color: var(--link-gray);
  font-size: .7rem;
}

.action-buttons {
  flex-wrap: nowrap;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.action-buttons a {
  white-space: nowrap;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  text-decoration: none;
  transition: all .2s ease-in-out;
}

.secondary {
  color: var(--link-gray-hover);
  border: 1px solid var(--gray-border);
  background: none;
  border-radius: .375rem;
  outline: none;
}

.secondary:hover {
  background: var(--secondary-background);
}

.primary {
  color: var(--white);
  background: var(--primary);
  border-radius: .375rem;
  padding: .5rem 1rem;
}

.primary:hover {
  background: var(--primary-hover);
}

.burger-menu {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  display: none;
}

@media (width <= 560px) {
  .hide {
    display: none;
  }

  .burger-menu {
    pointer-events: all;
    border: 1px solid var(--gray-border);
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .25rem;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    min-height: 2rem;
    display: flex;
  }

  .burger-menu svg {
    stroke: var(--link-gray);
    width: 1.25rem;
    height: 1.25rem;
  }

  .navigation {
    display: none;
  }

  .menu-icon {
    display: block;
  }

  .navigation--mobile {
    top: var(--navbar-height);
    background-color: var(--background);
    opacity: .95;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    min-height: 100%;
    padding: 5rem 3.5rem;
    animation: .3s forwards fadein;
    display: flex;
    position: absolute;
    right: 0;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
      width: 0;
      height: 0;
    }

    100% {
      opacity: 1;
      height: calc(100vh - var(--navbar-height));
      width: 100%;
    }
  }

  .navigation--mobile--fadeout {
    animation: .3s forwards fadeout;
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
      height: calc(100vh - var(--navbar-height));
      width: 100%;
    }

    100% {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
}
/*# sourceMappingURL=index.285e8f9f.css.map */
