

:root {
    --primary: #000000;
    --background: #FAFAFA;
    --secondary-background: #F2F2F2;
    --gray: #847F90;
    --gray-hover: #171717;
    --primary-hover: #555555;
    --link-gray: #666666;
    --link-gray-hover: #171717;
    --gray: #E1E1E1;
    --gray-border: #E6E6E6;
    --white: #FCFCFC;
    --navbar-height: 64px;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.menu__wrapper {
    display: flex;
    position: fixed;
    justify-content: space-between;
    width: 100vw;
    z-index: 2;
    gap: 1.5rem;
    background: var(--background);
    background-color:#EBEEE8;
    opacity: 0.8;
    height: var(--navbar-height);
    padding: 0px 1rem;
    align-items: center;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, .1);
}

.menu__bar {
    display: flex;
    align-items: center;
}

.logo {
    cursor: pointer;
    display: flex;
    display: none;
    align-items: center;
}

.logo svg {
    fill: var(--primary);
}

.navigation {
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    gap: 1rem;
    background: var(--background);
    background-color:transparent;
}

.navigation>li {
    display: flex;
    position: relative;
    cursor: pointer;
    align-items: center;
    min-height: 2.25rem;
}

.navigation>li>a {
    color: var(--link-gray);
    font-size: 14px;
    text-decoration: none;
}

.navigation>li>a:hover {
    color: var(--link-gray-hover);
}

.navigation>li>button {
    color: var(--link-gray);
    transition: all 0.3s ease;
    text-decoration: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    margin-right: -0.25rem;
    align-items: center;
    min-height: 2.25rem;
    font-size: 18px;
    gap: 0.25rem;
    font-family: 'Open Sans', sans-serif;
    flex-wrap: nowrap;
    border-radius: 10rem;
    padding: 0.25rem 0.6125rem;
    white-space: nowrap;
    background: none;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.navigation>li>button>svg {
    margin-top: 2px;
    fill: var(--link-gray);
    transition: all 0.2s ease-in-out;
}


.navigation>li:hover>button svg {
    fill: var(--link-gray-hover);
    rotate: 180deg;
}

.navigation>li:hover>button {
    color: var(--link-gray-hover);
    background: var(--gray);
}

.navigation>li:hover .dropdown, 
.navigation>li:focus-within .dropdown {
    display: block;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.99) 
        translateY(-0.7em);
        transform-origin: top;
    }
    to {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

.dropdown__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 3.75rem;
}

.dropdown {
    border-radius: 0.5rem;
    background: var(--white);
    display: none;
    padding: 0.5rem;
    width: 100%;
    max-width: 36rem;
    border: 1px solid var(--gray-border);
    box-shadow:
        0 50px 100px -20px rgba(0, 0, 0, .12),
        0 30px 60px -30px rgba(0, 0, 0, .15);
    z-index: 2;
    animation: fadeIn 0.2s ease-in-out;
}

.list-items-with-description {
    list-style-type: none;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
}

.list-items-with-description li {
    display: flex;
    padding: 0.5rem;
    gap: 1rem;
    width: 100%;
    transition: all 0.2s ease-in-out;
    border-radius: 0.375rem;
}

.list-items-with-description li:hover {
    background: var(--secondary-background);
}

.list-items-with-description li svg {
    stroke: var(--link-gray-hover);
    stroke-width: 1px;
    width: 1.5rem;
    height: 1.5rem;
}

.item-title h3 {
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--link-gray-hover);
}

.item-title p {
    font-size: 0.7rem;
    white-space: nowrap;
    color: var(--link-gray);
}

.action-buttons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: nowrap;
}

.action-buttons a {
    text-decoration: none;
    font-size: 0.875rem;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
    transition: all 0.2s ease-in-out;
    line-height: 1.25rem;
}

.secondary {
    background: none;
    color: var(--link-gray-hover);
    outline: none;
    border: 1px solid var(--gray-border);
    border-radius: 0.375rem;
}

.secondary:hover {
    background: var(--secondary-background);
}

.primary {
    color: var(--white);
    border-radius: 0.375rem;
    background: var(--primary);
    padding: 0.5rem 1rem;
}

.primary:hover {
    background: var(--primary-hover);
}

.burger-menu {
    display: none;
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
}

@media (max-width: 560px) {
    .hide {
        display: none; 
    }

    .burger-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        min-width: 2rem;
        min-height: 2rem;
        pointer-events: all;
        gap: 0.25rem;
        flex-direction: column;
        border: 1px solid var(--gray-border);
    }

    .burger-menu svg {
        height: 1.25rem;
        stroke: var(--link-gray);
        width: 1.25rem;
    }

    .navigation {
        display: none;
    }

    .menu-icon {
        display: block;
    }

    .navigation--mobile {
        top: var(--navbar-height);
        position: absolute;
        right: 0;
        display: flex;
        padding: 5rem 3.5rem;
        min-height: 100%;
        background-color: var(--background);
        gap: 0.5rem;
        flex-direction: column;
        align-items: flex-start;
        opacity: 0.95;
        animation: fadein 0.3s forwards;
    }

    @keyframes fadein {
        0% {
            opacity: 0;
            width: 0;
            height: 0;
        }

        100% {
            opacity: 1;
            width: 100%;
            height: calc(100vh - var(--navbar-height));
        }
    }

    .navigation--mobile--fadeout {
        animation: fadeout 300ms forwards;
    }

    @keyframes fadeout {
        0% {
            opacity: 1;
            width: 100%;
            height: calc(100vh - var(--navbar-height));
        }

        100% {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }

}